﻿/**
 *  @ngdoc controller
 *  @name startorder Directive
 *  @description Startorder Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('startorderModel', function () {
        return {
            controller: 'startorderController',
            restrict: 'E',
            scope: {
                modelId: "@",
                data: '=',
                filter: '='
            },
            templateUrl: 'app/directive/startorderPopup/startorderdir.html'
        }
    }).controller('startorderController', function ($scope, $state, orderDetailResource) {

        /**
        * @name addRemoveLens
        * @param {boolean} isTaken Indication of lens addition or removal
        * @author ajay
        * @description add or remove lens
        * @returns {boolean}
        */
        $scope.addRemoveLens = (isTaken) => {
            // debugger
            var takenLenses = $scope.data.orderLens.filter(x => x.isTaken === true);
            //Do not allow to remove if only single lens left from both lens
            if ((takenLenses.length > 1) || isTaken === false) {
                return !isTaken;
            }
            return isTaken;
        }

        /**
       * @name getOrderlensTypes
       * @param {object} lens eye lens object
       * @author priyanka
       * @description based on lens get the orderlens types
       * @returns {object} list of orderlensTypes
       */
        $scope.data.getOrderlensTypes = (lens) => {
            lens.warrantyDate = lens.invoices.length > 0 ? lens.invoices.find(p => p.isRight === lens.isRight && p.orderId === lens.orderId)?.warrantyDate : null;
            lens.parentOrderId = lens.invoices.length > 0 ? lens.invoices.find(p => p.isRight === lens.isRight && p.orderId === lens.orderId)?.parentOrderId : 0;
            orderDetailResource.getOrderLensTypes({ orderId: lens.parentOrderId, isRightEye: lens.isRight, currentOrderId: $scope.data.orderId }, function (res) {
                lens.orderLensTypes = res.allowedLenseTypes;
                lens.lensType = lens.lensType;                
            })
        }

        /**
      * @name startOrder
      * @param {object} lenses array of eye object
      * @author priyanka
      * @description onclick of start order get data based on lens
      * @returns {void}
      */
        //$scope.RxLensModel = [];
        $scope.data.startOrder = (lenses) => {

            // we need orderid and orderstatusid from orderdetails how to get that here ?

            var model = { orderLens: [] };
            //var selectedLenses = lenses.filter(x => x.isTaken === true);
            lenses.forEach(function (o) {
                model.orderLens.push({
                    isRight: o.isRight,
                    orderId: o.orderId,
                    isTaken: o.isTaken,
                    lensType: o.lensType,
                    warrantyDate: o.warrantyDate,
                    parentOrderid: o.parentOrderId
                })
            });

            var lensType = $scope.data.eyeLeftOrRight === "3" ? "OU" : ($scope.data.eyeLeftOrRight === "2" ? "OS" : "OD");
            if ($scope.data.state == "orderDetails") {
                $state.go('eyes', {
                    orderId: $scope.data.orderId,
                    jsond: JSON.stringify(model),
                    eye: lensType,
                    orderStatusId: $scope.data.orderStatusId,
                    isEdit: false,
                    startOrderId: 0
                });
            } else {
                //function body is situated in eyecCtrl.js
                $scope.data.storePrevStateData();
                var sessionData = JSON.parse(sessionStorage.getItem('previousOrderState'));
                $state.transitionTo('eyes', {
                    orderId: $scope.data.orderId,
                    jsond: JSON.stringify(model),
                    eye: lensType,
                    orderStatusId: $scope.data.orderStatusId,
                    isEdit: false,
                    startOrderId: sessionData.eyesOrder.startOrderId

                });
            }
        }
    });

}(window.angular));